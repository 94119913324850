<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="houseId"
    v-bind="dataTableAttrs"
    :headers="headers"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    disable-sort
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        title=""
        icon=""
        @reload="loadList"
        hide-edit
        hide-search
        :show-reload="false"
        ref="titleBar"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.resident="{ value, item }">
      <router-link
        :to="{
          name: 'definitions.residents.edit',
          params: { id: item.resident_id },
        }"
      >
        {{ value }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.entered_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.left_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_staying="{ item, value }">
      <rs-action @click="handleIsStayingClick(item)" :is-loading="isLoading">
        <rs-table-cell-boolean :value="value" />
      </rs-action>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "HousePeriodicalAssessmentList",
  mixins: [hasDataTable],
  props: {
    houseId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    // options: {
    //   handler() {
    //     this.loadList();
    //   },
    //   deep: true,
    // },
    // search: {
    //   handler: debounce(function () {
    //     this.loadList();
    //   }),
    //   deep: true,
    // },
    houseId() {
      this.loadList();
    },
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Kişi",
          value: "resident",
          searchable: "text",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "text",
        },
        {
          text: "Giriş Tarihi",
          value: "entered_on",
          searchable: "date",
        },
        {
          text: "Çıkış Tarihi",
          value: "left_on",
          searchable: "date",
        },
        {
          text: "Oturuyor",
          value: "is_staying",
          align: "center",
          searchable: "yesNo",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.houseId) {
        return;
      }

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );
      params.house_id = this.houseId;
      this.resetTable();
      this.isLoading = true;

      this.$api
        .query(`clusters/${this.clusterId}/house-users`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleIsStayingClick(item) {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .put(`house-users/${item.id}/is-staying`, {
          is_staying: !item.is_staying,
        })
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.handleWarnings(response);

          setTimeout(() => {
            this.loadList();
          }, 50);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
