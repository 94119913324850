<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      <h3>Otomatik Borçlandırma</h3>

      <HousingIncomeForm
        ref="customIncomeTypeForm"
        @saved="handleCustomIncomeTypeSaved"
      />
    </template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-select
              :items="incomeTypeList"
              :label="$t('labels.debt_type')"
              :rules="[rules.required]"
              v-model="formData.income_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
              @change="updateDescription"
            />
          </v-col>
          <v-col sm="6">
            <v-row dense cols="12" v-if="formData.income_type_id === 10">
              <v-col cols="8">
                <rs-select
                  :items="customIncomeTypeList"
                  :label="$t('labels.custom_expense_type')"
                  :rules="[rules.required]"
                  v-model="formData.child_income_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                  required
                  @change="updateDescription"
                />
              </v-col>
              <v-col cols="4" class="align-self-center">
                <v-btn
                  small
                  color="pink"
                  outlined
                  @click="handleAddCustomIncomeTypeClick"
                >
                  Ekle
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="6">
            <rs-select-resident
              label="Borçlu Kişi"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :house-id="onlyHouseUsers ? houseId : null"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              :readonly="disabled"
              :filled="disabled"
              @change="formData.resident_id = null"
            />
          </v-col>
          <v-col sm="6">
            <rs-select
              :items="autoAssessmentTimingTypeList"
              :label="$t('labels.assessment_timing_type')"
              :rules="[rules.required]"
              v-model="formData.issue_auto_assessment_timing_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              v-if="
                [1, 2, 8, 9].indexOf(
                  formData.issue_auto_assessment_timing_type_id
                ) > -1
              "
              type="number"
              min="1"
              max="31"
              step="1"
              :label="$t('labels.assessment_timing_day')"
              v-model="formData.issue_day"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              hint="Ayın 1'i ile 31'i arasında bir gün seçin"
            />
          </v-col>
          <v-col sm="6">
            <rs-select
              :items="autoAssessmentTimingTypeList"
              :label="$t('labels.last_payment_timing_type')"
              :rules="[rules.required]"
              v-model="formData.last_payment_auto_assessment_timing_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              v-if="
                [1, 2, 8, 9].indexOf(
                  formData.last_payment_auto_assessment_timing_type_id
                ) > -1
              "
              type="number"
              min="1"
              max="31"
              step="1"
              :label="$t('labels.last_payment_timing_day')"
              v-model="formData.last_payment_day"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              hint="Ayın 1'i ile 31'i arasında bir gün seçin"
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount_per_house"
              :label="$t('labels.debt_amount')"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount_per_house, 0.01),
                      rules.max(formData.amount_per_house, 9999999.99),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="6">
            <rs-select-project
              :label="$t('labels.project_code')"
              v-model="formData.project_id"
              :clearable="!disabled"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6">
            <rs-datepicker
              label="Geçerlilik Başlangıç Tarihi"
              v-model="formData.starts_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-datepicker
              label="Geçerlilik Bitiş Tarihi"
              v-model="formData.ends_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-select
              :items="[
                { id: 'daily', name: 'Günlük' },
                { id: 'monthly', name: 'Aylık' },
              ]"
              :label="$t('labels.delay_calculation_type')"
              v-model="formData.deferment_calculation_period"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              :label="$t('labels.delay_percentage')"
              v-model="formData.deferment_interest_percent"
              :readonly="disabled"
              :filled="disabled"
              prefix="%"
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              :label="$t('labels.delay_option_days')"
              v-model="formData.deferment_grace_period"
              :readonly="disabled"
              :filled="disabled"
              suffix="Gün"
            />
          </v-col>
          <v-col sm="6">
            <rs-is-active
              v-model="formData.is_active"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col>
            <rs-textarea
              :label="$t('labels.description')"
              :rules="[rules.required]"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide"
          :hide-submit="true"
          @submit.prevent="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import { HousingIncomeForm } from "@/view/pages/income/forms";

export default {
  name: "AutoAssessmentForm",
  mixins: [hasForm],
  components: {
    HousingIncomeForm,
  },
  props: {
    house: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      "autoAssessmentTimingTypeList",
      "cluster",
      "clusterId",
      "incomeTypeList",
      "customIncomeTypeList",
    ]),
  },
  data() {
    return {
      id: null,
      houseId: null,
      onlyHouseUsers: true,
      formData: {
        amount_per_house: null,
        assessment_type_id: 1,
        child_income_type_id: null,
        debitor_type_id: 1,
        deferment_calculation_period: null,
        deferment_interest_percent: null,
        deferment_grace_period: null,
        description: null,
        ends_on: null,
        income_type_id: null,
        is_active: true,
        issue_auto_assessment_timing_type_id: null,
        issue_day: null,
        last_payment_auto_assessment_timing_type_id: null,
        last_payment_day: null,
        resident_id: null,
        project_id: null,
        starts_on: null,
      },
    };
  },
  methods: {
    show(id, extraParams) {
      this.$nextTick(() => {
        this.formData = Object.assign(
          {
            amount_per_house: null,
            assessment_type_id: 1,
            child_income_type_id: null,
            debitor_type_id: 1,
            deferment_calculation_period:
              this.cluster.deferment_calculation_period,
            deferment_interest_percent: this.cluster.deferment_interest_percent,
            deferment_grace_period: this.cluster.deferment_grace_period,
            description: null,
            ends_on: null,
            income_type_id: null,
            is_active: true,
            issue_auto_assessment_timing_type_id: null,
            issue_day: null,
            last_payment_auto_assessment_timing_type_id: null,
            last_payment_day: null,
            resident_id: null,
            project_id: null,
            starts_on: null,
          },
          extraParams
        );

        this.id = null;
        this.houseId = null;

        if (id) {
          this.id = id;
          this.load();
        } else if (extraParams && extraParams.house_id) {
          this.houseId = extraParams.house_id;
        }

        this.$refs.sidePanel.show();
      });
    },
    load() {
      this.isLoading = true;

      this.$api
        .get("income/periodical-assessments", this.id)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .then(() => (this.isLoading = false));
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);
      formData.cluster_id = this.clusterId;
      formData.house_id = this.houseId;

      this.isLoading = true;

      if (this.id) {
        this.$api
          .put(`income/periodical-assessments/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("income/periodical-assessments", formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.id = response.data.data.id;

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleAddCustomIncomeTypeClick() {
      this.$refs.customIncomeTypeForm.show();
    },
    handleCustomIncomeTypeSaved(newItem) {
      if (newItem) {
        this.formData.child_income_type_id = newItem.id;
      }
    },
    updateDescription() {
      if (!this.formData.income_type_id) {
        this.formData.description = null;
        return;
      }

      let description = "";

      for (const index in this.incomeTypeList) {
        if (this.incomeTypeList[index].id === this.formData.income_type_id) {
          description += "[" + this.incomeTypeList[index].name + "]";
        }
      }

      if (this.formData.income_type_id !== 10) {
        this.formData.child_income_type_id = null;
      } else {
        for (const index in this.customIncomeTypeList) {
          if (
            this.customIncomeTypeList[index].id ===
            this.formData.child_income_type_id
          ) {
            description += " [" + this.customIncomeTypeList[index].name + "]";
          }
        }
      }

      this.formData.description = description;
    },
  },
};
</script>
